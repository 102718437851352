.sliders-container {
    position: relative;
    margin-top: 45px;
}

.sliders-placeholder {
    position: absolute;
    width: 110%;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.5);
    top:-10px;
    left: -10px;
}


.actions-container {
    
}
.actions-container {}
.actions-container .column {}
.actions-container .column:first-child {}
.actions-container .column:last-child {
    text-align: right;
}

.actions-container .column button {
    text-transform: uppercase;
    border-radius: 0px;
    padding: 14px 39px;
    border-width: 1px;
    font-weight: normal;
    font-size: 16px;
}
.actions-container .column:first-child button {
    color: #A6A6A6;
    border: 1px solid #A6A6A6;
    background-color: white;
}
.actions-container .column:last-child button {
    border-color: #c7001f;
    background-color: #c7001f;
    color: white;
}

.version-description {
    position: absolute;
    top:80px;
    color: #A6A6A6;
}

.version-description img {
    vertical-align: sub;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .actions-container .column button{
        padding: 14px 7px;
    }
    
    .select-engine-page .for-mobile .car-placeholder{
        width: 300px !important;
    }
    .select-engine-page .for-mobile .car-placeholder .car-image {
        width: 70%;
        bottom: -22px !important;
    }

    .version-description {
        top: 204px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }