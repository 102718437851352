.select-cost-page {
    padding-top: 100px;
}

.select-cost-page table {
    width: 100%;
    border-top: 1px solid #A6A6A6;
    border-collapse: collapse;
}

.select-cost-page table thead {
    border-bottom: 1px solid #A6A6A6;
}

.select-cost-page table th {
    color: #A6A6A6;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

.select-cost-page table th:first-child {
    text-align: left;
}

.select-cost-page table td {
    border: 1px solid #A6A6A6;
    padding: 15px;
    text-align: right;
    font-weight: 400;
    font-size: 18px;
}

.select-cost-page table td:first-child {
    text-align: left;
}
.select-cost-page table td:last-child {
    font-weight: 700;
}

.select-cost-page table th {
    border: 1px solid #A6A6A6;
    padding: 15px;
}
.select-cost-page table th:first-child {
    border-left: 0;
}
.select-cost-page table th:last-child {
    border-right: 0;
}
.select-cost-page table tr:first-child td {
    border-top: 0;
}
.select-cost-page table tr td:first-child {
    border-left: 0;
}
.select-cost-page table tr:last-child td {
    border-bottom: 0;
}
.select-cost-page table tr td:last-child {
    border-right: 0;
}


.select-cost-page .total-table {
    margin-top: 50px;
    font-size: 18px;
}
.select-cost-page .total-table .row {
    padding-top: 10px;
    padding-bottom: 10px;
}
.select-cost-page .total-table .row .column {}
.select-cost-page .total-table .row .column:first-child {
    text-align: right;
}
.select-cost-page .total-table .row .column:last-child {
    text-align: right;
}



.select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table td:last-child,
.select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table th:last-child{
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
}

.select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table td:last-child {
    padding-right: 70px;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table td:last-child,
    .select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table th:last-child{
        width: auto !important;
        max-width: none !important;
        min-width: auto !important;
    }

    .select-cost-page table td {
        padding: 0px;
        text-align: center;
    }
    .select-cost-page table th {
        padding: 0px;
    }
    .select-cost-page .custom-accordion-container .custom-accordion-item .custom-accordion-content table td:last-child {
        padding-right: 0px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }