.for-mobile {
    display: none;
}

.for-desktop {
    display: block;
}

span.for-desktop, img.for-desktop {
    display: inline;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .for-mobile {
        display: block;
    }

    .for-desktop {
        display: none !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .for-mobile {
        display: block;
    }

    .for-desktop {
        display: none !important;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}



.loader,
.loader:before,
.loader:after {
    background: #c7001f;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.loader {
    color: #c7001f;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}
.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 1.5em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
