.cars-container {
    margin-left: -20px;
    margin-right: -20px;
}

.car-from-lk-container {
    width: 100%;
    height: 188px;
    background: linear-gradient(116.72deg, #BABABA 19.79%, #F3F3F3 95.91%);
    border-radius: 25px;
    cursor: pointer;
    margin: 20px;
    display: inline-block;
    color: white;
    font-weight: bold;
    font-family: 'HarmonyOS-sans-Regular';
    padding-top: 15px;
    padding-right: 45px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 32px;
    position: relative;
    line-height: 35px;
    vertical-align: top;
}

.car-from-lk-container .car-image {
    position: absolute;
    bottom: -41px;
    right: -29px;
    width: 40%;
    transition: 400ms;
}
.car-from-lk-container:hover .car-image {
    width: 50%;
}

.car-from-lk-container .vin {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 15px;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .cars-container {
        margin-left: 0px;
        margin-right: 0px;
    }

    .cars-container>div:first-child {
        margin-bottom: 35px;
    }

    .car-placeholder {
        width: 140px !important;
        height: 142px !important;
        margin: 4px !important;
        padding-top: 10px !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        font-size: 20px !important;
        line-height: 25px !important;
    }
    .car-placeholder .car-image {
        bottom: -9px !important;
        right: -4px !important;
    }

    .car-from-lk-container {
        width: 80%;
        height: 200px;
    }

    .car-from-lk-container .car-image {
        display: none;
    }

    .car-from-lk-container .vin {
        bottom: 20px;
        left: 20px;
    }

    .cars-container .custom-input-container {
        width: calc(100% - 15px);
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }
