.car-placeholder {
    width: 265px;
    height: 188px;
    background: linear-gradient(116.72deg, #BABABA 19.79%, #F3F3F3 95.91%);
    border-radius: 25px;
    cursor: pointer;
    margin: 20px;
    display: inline-block;
    color: white;
    font-weight: bold;
    font-family: 'HarmonyOS-sans-Regular';
    padding-top: 15px;
    padding-right: 45px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 32px;
    position: relative;
    line-height: 35px;
    vertical-align: top;
}

.car-placeholder .car-image {
    position: absolute;
    bottom: -41px;
    right: -29px;
    width: 100%;
    transition: 400ms;
}

.car-placeholder:hover .car-image {
    width: 115%;
}

.car-placeholder .marker {
    background: #C7001F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 16px;
    top: 16px
}
