.custom-slide-container {}

.custom-slide-container .title {
    margin-top: 25px;
    margin-bottom: 15px;
}
.custom-slide-container .rc-slider-handle {
    border: solid 2px #C7001F !important;
    background-color: #C7001F !important;
}

.custom-slide-container .rc-slider-track {
    background-color: #C7001F !important;
}

.custom-slide-container .scale {
    width: 111%;
}

.custom-slide-container .scale .scale-item {
    display: inline-block;
    position: relative;
    border-left: 1px solid black;
    height: 30px;
}

.custom-slide-container .scale .scale-item .content {
    position: absolute;
    left: -4px;
    bottom: 0px;
    background-color: white;
}
