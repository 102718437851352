.service-calculator {
    padding-top: 100px;
}

.service-calculator.without-menu {
    padding-top: 30px;
}

.service-calculator .menu-items-container {
    padding: 0px !important;
}
.service-calculator .menu-items-container .menu-item {
    margin-bottom: 60px;
    position: relative;
}

.service-calculator .menu-items-container .menu-item .menu-item-image-container {
    width: 48px;
    height: 48px;
    padding-top: 9px;
    padding-left: 9px;
    border-radius: 100px;
    border: 1px solid #A6A6A6;
    display: inline-block;
    vertical-align: top;
}
.service-calculator .menu-items-container .menu-item .menu-item-image-container.active {
    background-color: #c7001f;
    border: 1px solid #c7001f;
}

.service-calculator .menu-items-container .menu-item .menu-item-image-container.is-complete {
    background-color: #28C37D;
    cursor: pointer;
}

.service-calculator .menu-items-container .menu-item .menu-item-image-container img {}

.service-calculator .menu-items-container .menu-item .menu-item-description-container {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    width: 166px;
}
.service-calculator .menu-items-container .menu-item .menu-item-description-container .title {
    font-size: 16px;
    color: #A6A6A6;
    margin-bottom: 8px;
}
.service-calculator .menu-items-container .menu-item .menu-item-description-container .description {
    font-size: 16px;
    font-weight: bold;
}
.service-calculator .menu-items-container .menu-item .menu-item-description-container .selected {
    position: absolute;
}

.service-calculator .menu-items-container .menu-item .string {
    width: 2px;
    position: absolute;
    top: 53px;
    left: 23px;
    background-color: #A6A6A6;
}
.service-calculator .menu-items-container .menu-item .string.active {
    background-color: #c7001f;
}
.service-calculator .menu-items-container .menu-item .string.is-complete {
    background-color: #28C37D;
}



.service-calculator .main-title {
    font-family: 'HarmonyOS-sans-Regular';
    font-size: 32px;
    border-left: 4px solid #c7001f;
    padding-left: 20px;
    height: 35px;
    padding-top: 9px;
    line-height: 30px;
}

.finish-block {
    width: 490px;
    margin-left: auto;
    margin-right: auto;
}
.finish-block .image-container {
    text-align: center;
}
.finish-block .title {
    font-size: 24px;
    margin-bottom: 25px;
}
.finish-block .dialer-info {}
.finish-block .date-info {}
.finish-block .car-info {}
.finish-block .info-message {
    margin-top: 25px;
    margin-bottom: 25px;
}
.finish-block .action-container {}

.finish-block .action-container button,
.finish-block .action-container button:hover,
.finish-block .action-container button:active {
    text-transform: uppercase;
    border-radius: 0px;
    padding: 14px 39px;
    border-width: 1px;
    font-weight: normal;
    font-size: 16px;
    border-color: #c7001f !important;
    border: none;
    background-color: #c7001f;
    color: white;
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
}

.main-content-container {
    padding-left: 45px !important;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .service-calculator .menu-items-container {
        padding-left: 15px !important;
    }

    .service-calculator .main-title {
        font-size: 18px;
        padding-top: 0px;
        padding-left: 10px;
    }
    .main-content-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .service-calculator.without-menu {
        padding-top: 60px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }
