.custom-accordion-container {}
.custom-accordion-container .custom-accordion-item {}
.custom-accordion-container .custom-accordion-item .custom-accordion-header {
    position: relative;
    height: 80px;
    border-bottom: 1px solid #A6A6A6;
    padding-top: 30px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 400;
}

.custom-accordion-container .custom-accordion-item:first-child .custom-accordion-header {
    border-top: 1px solid #A6A6A6;
}

.custom-accordion-container .custom-accordion-item .custom-accordion-header.active {
    border-bottom: none;
}

.custom-accordion-container .custom-accordion-item .custom-accordion-header .label {
    position: absolute;
    top: 30px;
    right: 72px;
    font-weight: bold;
}

.custom-accordion-container .custom-accordion-item .custom-accordion-header img {
    position: absolute;
    top: 20px;
    right: 21px;
}
.custom-accordion-container .custom-accordion-item .custom-accordion-content {
    border-bottom: 1px solid #A6A6A6;
    padding-bottom: 20px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .custom-accordion-header {
        padding-right: 180px;
        padding-top: 5px !important;
        height: 90px !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }