.search-row-container {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
}

.search-row-container .input{
    width: 100%;
}

.search-row-container .button{
    width: 100%;
}

.city-examples-block {
    position: relative;
}

.city-examples-block .city-example-item {
    display: inline-block;
    border: 1px solid #A6A6A6;
    padding: 10px 30px;
    margin-right: 15px;
    color: #A6A6A6;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.city-examples-block .city-example-item:last-child {
    margin-right: 0;
}

.city-examples-block .city-example-item:hover {
    background-color: #c7001f;
    color: white;
    border: 1px solid #c7001f;
}

.map-container-block {
    margin-bottom: 50px;
    position: relative;
}

.map-container-block .loader-map {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 350px;
    opacity: 0.5;
    position: absolute;
    top: 0;
}

.map-container-block .dealer-list-block {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: white;
    width: 450px;
    overflow-y: auto;
}

.map-container-block .dealer-list-block .dealers-not-found-message {
    text-align: center;
    padding-top: 30px;
}
.map-container-block .dealer-list-block .dealer-item {
    position: relative;
    height: 60px;
    margin-bottom: 20px;
    cursor: pointer;
}

.map-container-block .dealer-list-block .dealer-item .select-indicator {
    position: absolute;
    left: 5px;
    top: 1px
}

.map-container-block .dealer-list-block .dealer-item .name {
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 8px
}
.map-container-block .dealer-list-block .dealer-item .address {
    position: absolute;
    left: 40px;
    top: 30px;
    width: 70%;
}
.map-container-block .dealer-list-block .dealer-item .price {
    font-weight: 700;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 50px
}

.map-container-block .get-my-coords {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.map-switcher-btn {
    color: #C7001F;
    text-transform: uppercase;
    background: #FFFFFF;
    border: 1px solid #C7001F;
    border-radius: 0px;
    padding: 10px 10px;
    width: 100%;
    cursor: pointer;
}

.search-on-map-input {
    width: 100%;
    border: 1px solid #A6A6A6;
    border-radius: 0px;
    padding: 10px 10px;
    outline: none;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .city-examples-block {
        overflow-x: scroll;
        scrollbar-width: none;
    }
    .city-examples-block::-webkit-scrollbar {
        display: none;
    }

    .city-examples-block .city-examples-block-inner {
        width: 1000px;
    }
    .map-switcher-btn {
        width: auto;
        padding: 7px;
    }
    .map-switcher-btn.active {
        background-color: #c7001f;
    }
    .map-switcher-btn img {
        margin: 0 !important;
    }

    .map-container-block .dealer-list-block {
        width: 100%;
    }

    .map-container-block .dealer-list-block .dealer-item {
        height: 140px;
    }
    .map-container-block .dealer-list-block .dealer-item .address {
        top: 50px;
    }

    .map-container-block .dealer-list-block .dealer-item .price {
        top: 118px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }