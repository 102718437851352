.custom-input-container {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.custom-input-container .custom-input {
    width: 100%;
    border: 1px solid #A6A6A6;
    font-size: 17px;
    padding: 15px 10px;
    border-radius: 0px !important;
}

.custom-input-container .custom-input.is-required {
    padding: 15px 17px;
}

.custom-input-container input:focus-visible.custom-input {
    border-radius: 0px !important;
    border: 1px solid #A6A6A6 !important;
    outline: none;
}

.custom-input-container .required-marker {
    position: absolute;
    top: 12px;
    left: 9px;
    color: #C7001F;
}
