.header-container {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    width: 100%;
    height: 51px;
    background-color: white;
    z-index: 1000;
}

.header-container .container {
    position: relative;
}

.header-container .container .logo {
    position: absolute;
    top: 14px;
    left: 0px;
    max-width: 181px;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {  }

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {

}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {
    .service-calculator .menu-items-container .menu-item .menu-item-description-container {
        width: 130px !important;
    }
}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }
