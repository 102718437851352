.required-fields-description {
    text-align: right;
    color: #A6A6A6;
    font-size: 16px;
}

.required-fields-description span {
    color: #c7001f;
}

.checkboxs-container {
    width: 50%;
}
/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .checkboxs-container {
        width: 100%;
    }   
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }