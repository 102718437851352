.select-time-slot-page {
    padding-top: 50px;
}
.select-time-slot-page .comment-input {
    width: 100%;
    resize: none;
    border-radius: 0px !important;
    padding: 10px 15px;
    outline: none;
    border: 1px solid #A6A6A6;
}
.select-time-slot-page .time-slots {
    padding-top: 15px;
    height: 318px;
}
.select-time-slot-page .time-slots div {
    border: 1px solid #A6A6A6;
    display: inline-block;
    padding-top: 7px;
    margin-right: 13px;
    font-size: 16px;
    cursor: pointer;
    width: 76px;
    margin-bottom: 15px;
    text-align: center;
    height: 37px;
}

.select-time-slot-page .time-slots div:hover {
    background: rgba(199, 0, 31, 0.5);
    border: 1px solid rgba(199, 0, 31, 0.5);
}

.select-time-slot-page .time-slots div.selected {
    background: #C7001F;
    border: 1px solid #C7001F;
    cursor: default;
    color: white;
}

.select-time-slot-page .create-request, 
.select-time-slot-page .create-request:hover, 
.select-time-slot-page .create-request:active {
    text-transform: uppercase;
    border-radius: 0px;
    padding: 14px 39px;
    border-width: 1px;
    font-weight: normal;
    font-size: 16px;
    border-color: #c7001f !important;
    border: none;
    background-color: #c7001f;
    color: white;
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
    margin-bottom: 25px;
}

.select-time-slot-page .calendar {
    height: 382px;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
    background-color: #c7001f !important;
}

.rdp-weeknumber, .rdp-day {
    border-radius: 0 !important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: rgba(199, 0, 31, 0.5) !important;
}

.select-time-slot-page .text-date-description {
    font-weight: 600;
    font-size: 16px;
    font-style: normal;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .select-time-slot-page .grid {
        display: block;
    }
    .select-time-slot-page .grid .row {
        display: block;
    }
    .select-time-slot-page .ui.grid>.row>[class*="eight wide"].column {
        width: 100% !important;
        display: block;
    }

    .select-time-slot-page .calendar {
        height: 334px;
    }

    .finish-block {
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }